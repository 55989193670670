import { useEffect } from "react";
import { getProviders, signIn, ClientSafeProvider } from "next-auth/react";
import { NextPageContext } from "next";
import { Loader } from "../../components";
import { getLayout } from "../../components/Layout/CenterDialog";
import { BuiltInProviderType } from "next-auth/providers";

type Props = {
  providers: Record<BuiltInProviderType, ClientSafeProvider>;
};

const SignIn = ({ providers }: Props) => {
  useEffect(() => {
    if (providers?.auth0) {
      void signIn(providers.auth0.id, {
        callbackUrl: "/auth/link-accounts",
      });
    }
  }, [providers]);

  return <Loader spin size={40} />;
};

SignIn.getLayout = getLayout;

export const getServerSideProps = async (context: NextPageContext) => {
  // early redirect if URL contains `error` query parameter
  if (typeof context?.query?.error === "string") {
    return {
      redirect: {
        destination: `/auth/error?error=${context.query.error}`,
        permanent: false,
      },
    };
  }

  const providers = await getProviders();

  return {
    props: {
      providers,
      title: "Loading...",
    },
  };
};

export default SignIn;
