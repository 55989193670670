import React from "react";
import styled from "styled-components";
import { Layout } from "../../components/Layout";
import Image from "next/image";

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  justify-items: center;
  align-items: center;
  height: calc(100vh - 8rem);
  padding: 4rem 0;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

type PageProps = {
  title: string;
  children: React.ReactNode;
};

export const getLayout = (
  page: React.ReactNode,
  pageProps: Omit<PageProps, "children">
) => (
  <Layout title={pageProps.title} wrapper={Content}>
    <Grid>
      <Image
        src="/questdb-logotype.svg"
        alt="QuestDB"
        width="300"
        height="60"
      />
      {page}
    </Grid>
  </Layout>
);
